import '@warp-ds/icons/elements/sofa-42';
import '@warp-ds/icons/elements/job-42';
import '@warp-ds/icons/elements/minivan-42';
import '@warp-ds/icons/elements/real-estate-42';
import '@warp-ds/icons/elements/airplane-42';
import '@warp-ds/icons/elements/phone-badge-check-42';
import '@warp-ds/icons/elements/sailboat-42';
import '@warp-ds/icons/elements/cabin-42';
import '@warp-ds/icons/elements/motorcycle-42';
import '@warp-ds/icons/elements/tractor-42';
import '@warp-ds/icons/elements/nettbil-light-42';
import '@warp-ds/icons/elements/remppatori-light-42';
import '@warp-ds/icons/elements/airplane-hotel-42';
