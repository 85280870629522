/**
 * This is the main entrypoint for you client side resources.
 * Import any modules that should be available on the page.
 */
import pulse from '@finn-no/pulse-sdk';

import './components/market-icons.js';
import './components/categories-section.js';

// Pulse tracking.
pulse.trackPageView({
    object: {
        type: 'Frontpage',
    },
    vertical: {
        name: 'common_pages',
    },
});
