import '@warp-ds/icons/elements/chair-42';
import '@warp-ds/icons/elements/smart-phone-42';
import '@warp-ds/icons/elements/shirt-42';
import '@warp-ds/icons/elements/tools-42';
import '@warp-ds/icons/elements/stroller-42';
import '@warp-ds/icons/elements/minivan-42';
import '@warp-ds/icons/elements/ice-skater-42';
import '@warp-ds/icons/elements/sailboat-42';
import '@warp-ds/icons/elements/guitar-bat-42';
import '@warp-ds/icons/elements/motorcycle-42';
import '@warp-ds/icons/elements/ice-skater-42';
import '@warp-ds/icons/elements/paw-42';
import '@warp-ds/icons/elements/tractor-42';
import '@warp-ds/icons/elements/vase-42';
import '@warp-ds/icons/elements/store-front-42';
