import '@warp-ds/icons/elements/chair-42';
import '@warp-ds/icons/elements/oikotie-light-42';
import '@warp-ds/icons/elements/shirt-42';
import '@warp-ds/icons/elements/smart-phone-42';
import '@warp-ds/icons/elements/stroller-42';
import '@warp-ds/icons/elements/tools-42';
import '@warp-ds/icons/elements/ice-skater-42';
import '@warp-ds/icons/elements/paw-42';
import '@warp-ds/icons/elements/car-part-42';
import '@warp-ds/icons/elements/guitar-bat-42';
import '@warp-ds/icons/elements/autovex-light-42';
import '@warp-ds/icons/elements/vase-42';
import '@warp-ds/icons/elements/remppatori-light-42';
